import React from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';



const NotFoundPage = (props) => {
  const {t} = props;

  return (
      <div className={'ui grid'} style={{paddingTop:'300px'}}>
        <Helmet>
          <title>{t("not_found:404.title")}</title>
        </Helmet>
        <div className={'row centered'}>
          <div
              className={
                'center aligned seven wide computer seven wide mobile seven wide tablet column'
              }
          >
            <i
                aria-hidden="true"
                className="frown outline icon massive"
                style={{ color: '#304c72' }}
            />
            <h2 className={'subheader-text'}>{t('not_found:404.title')}</h2>
            <p className={'blue-text'}>{t('not_found:404.text')}</p>
          </div>
        </div>
      </div>
  )
}
export default withTranslation(['not_found'])(NotFoundPage);
